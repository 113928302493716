<template>
	<b-modal id="pin-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" style="top:30%;">

		<template #modal-header> 
			<div> </div>
  <div id="content" v-if="state == 'pin'"> 
          
		<form  v-on:submit.prevent="verifyPin" class="text-center">
			<p class="text-center">Enter your PIN: </p>
			<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
      <div class=" text-center" v-if="showSuccess"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="pin-wrapper" >
					<div>
					  <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
					</div>

					<div>
						<input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
					  </div>
			
					  <div>
						<input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
					  </div>
		  
				  </div> 
			</div>
	
      <div class="form-group ">
				<button type="submit" class="btn btn-main">SUBMIT</button>
			</div>
		</form>
    <div class="form-group text-center">
				<button  class="btn btn-main" @click="close">CLOSE</button>
		</div>
	</div>
	<div class="text-center" v-if="state == 'otp'"> 
		<form  v-on:submit.prevent="verifyOtp">
			<p class="text-center">Enter the one-time PIN you received {{ maskMsg }} :</p>
			<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
      <div class=" text-center" v-if="showSuccess"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="otp-wrapper" >
				<div>
					<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
				</div>
				</div>
			</div>
	    <div class="text-center">
	         <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
	        <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
			</div>
      <div class="form-group">
				<button type="submit" class="btn btn-main" >SUBMIT</button>
			</div>
		</form>
	</div>
			<div></div>

    </template>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  props:["emailAddress", "mobilePhone", "mobileCode", "option", "twoFa"],
  name: 'PinModal',
  components: {
  },
  data: function() {
      return  {
        maskMsg: "",
      	state: 'pin',
        pinArr: [],
		    otpArr: [],
        systemMsg: '',
        showError: false,
		    showSuccess: false,
        isOtpDisabled: false,
        ifResent: false,
        timerInSeconds: 60,

      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
      resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },
    
      verifyPin() {

          let emailorMobile = "";
          let url =""
          if(this.option == 'email'){
            emailorMobile = this.emailAddress;
            url = "/cx/profile/validatepin";
           }
           if(this.option == 'mobilePhone'){
            emailorMobile = this.mobileCode + this.mobilePhone;
            url = "/cx/profile/validatepin";
           }

           if(this.option == "twofa") {
            url = "/cx/private/validatepin";
           }

            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                emailorMobile: emailorMobile
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: url,
                };
                this.axios(options)
                    .then((response) => {
						          this.state = 'otp'
                      this.clearError();
                      this.timer();
                      this.maskMsg = response.data.msg;
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
                        this.showError = true
                        this.systemMsg = error.response.data.msgText
                        console.log(error.response.data.msgText)
                        setTimeout(()=>{
                        this.clearError()
                        },3000);
					});

        },

        clearError() {
          this.systemMsg = ""
          this.showError = false
        },

		verifyOtp() {
			// this.isOtpDisabled = true
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
                      console.log(response.data)
                     if(this.option == 'email'){
                      this.updateEmail();
                     }
                     if(this.option == 'mobilePhone'){
                      this.updatePhone();
                     }
                      
                      if(this.option == 'twofa') {
                        this.updateTwoFa();
                      }
                      

                    })
                    .catch((error) => {
                        // Error code goes here
						            // this.isOtpDisabled = false
						      this.showError = true
            			this.otpArr = []
                        this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},3000);
				});
        },


      updateTwoFa() {
            const params = {
                twoFa: this.twoFa,
                authzCode: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: 'cx/profile/update2fa',
            };
            this.axios(options)
                .then((response) => {
                    this.showSuccess = true;
                    this.systemMsg = "2FA successfully updated";
                   
                   
                    setTimeout(() => {
                      window.location.reload();
                      this.showSuccess = false;
                      this.systemMsg = "";
                     }, 3000);

                   
                })
                .catch((error) => {

                    this.showError = true;
                    this.systemMsg = error.response.data.fieldErrors[0].defaultMessage;

                    setTimeout(() => {
                      this.$bvModal.hide('pin-modal'); 
                        this.showError = false;
                        this.systemMsg = "";
                    }, 3000)
                });
        },
		isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {
		    if(this.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		  }
      },

		 close: function(){
      window.location.reload();
   				this.$bvModal.hide('pin-modal'); 
   		},
      updateEmail() {
            const params = {
                emailAddress: this.emailAddress,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updateemail',
            };
            this.axios(options)
                .then((response) => {
                    this.showSuccess = true;
                    this.systemMsg = "Email successfully updated";
                   
                   
                    setTimeout(() => {
                      window.location.reload();
                      this.showSuccess = false;
                      this.systemMsg = "";
                     }, 3000);

                   
                })
                .catch((error) => {

                    this.showError = true;
                    this.systemMsg = error.response.data.fieldErrors[0].defaultMessage;

                    setTimeout(() => {
                      this.$bvModal.hide('pin-modal'); 
                        this.showError = false;
                        this.systemMsg = "";
                    }, 3000)
                });
        },
        updatePhone() {
            const params = {
                mobilePhone: this.mobileCode + this.mobilePhone,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updatemobile',
            };
            this.axios(options)
                .then((response) => {
                    this.showSuccess = true;
                    this.systemMsg = "Mobile Phone successfully updated";
                    setTimeout(() => {
                      window.location.reload();
                      this.showSuccess = false;
                      this.systemMsg = "";
                     }, 3000);

                    setTimeout(() => {
                        this.showSuccess = false;
                        this.systemMsg = "";
                    }, 3000)
                })
                .catch((error) => {

                    this.showError = true;
                    this.systemMsg = error.response.data.fieldErrors[0].defaultMessage;

                    setTimeout(() => {
                      this.$bvModal.hide('pin-modal'); 
                        this.showError = false;
                        this.systemMsg = "";
                    }, 3000)
                });
        },
   	},
   	mounted(){
      this.timer();
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   p {
	   margin-top:0px;
	   padding-top:0px;
   }

   h5 {
	   font-weight:bold;
	   margin-top:5px;
     font-size:2em;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }

	.btn-main {
   width:40%;
 }

	@media screen and (max-width:1000px) {
		div {
			font-size:0.86em;
		}

		div h5 {
			font-size:2em;
		}
	}
  .otp-wrapper, .pin-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
			  padding-bottom:7.5px;
      }
    }
	}
	
</style>
