<template>
  <div id="profile">
    <!-- <vue-metamask 
        userMessage="msg" 
        @onComplete="onComplete"
    >
    </vue-metamask> -->
    <Sidebar 
      :active="active" 
      v-if="showSidebar" 
      @close="toggleSidebar" 
      @logout="logout" 
    />
    <div id="content" >
        
        <div style="font-size:2.5rem;">
            <b-icon icon="arrow-bar-left" class="border rounded p-2" @click="toggleSidebar" style="cursor:pointer;"></b-icon>
        </div>

        <h4 class="form-title mb-4">{{ $t('profile.updateProfile') }}</h4>
            <div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
			<div class="alert alert-success" v-if="showSuccess"> {{ systemMsg }} </div>


        <div class="card">
            <form class="change-pin-form mt-4"  >
            <label for="new-pin">{{ $t('profile.updateProfile') }}</label>
            <div class="form-group mb-4"> 
                <div class="form-group mb-4">
                <label for="confirm-new-pin1">{{ $t('profile.firstName') }}</label>
                <input type="text" id="confirm-new-pin1" class="form-control"  :disabled="!isProfileEditing"  v-model="profile.firstName">
            </div>
            <div class="form-group mb-4">
                <label for="confirm-new-pin2">{{ $t('profile.lastName') }}</label>
                <input type="text" id="confirm-new-pin2" class="form-control"  :disabled="!isProfileEditing"  v-model="profile.lastName">
            </div>
            <button class="btn btn-save" v-if="!isProfileEditing"  type="button" @click="editProfile" > {{ $t('profile.edit') }}</button>
            <button class="btn btn-save" v-if="isProfileEditing"  type="button" @click="updateProfile()">{{ $t('profile.save') }}</button>
             <button v-if="isProfileEditing"  class="btn btn-save" type="button" @click="isProfileEditing = false">{{ $t('profile.cancel') }}</button>
            </div>
        </form>
        </div>
        <div class="card"> 
            <form class="change-pin-form mt-4" >
            <div class="form-group mb-4">
                <label for="current-pin">{{ $t('profile.updateEmail') }}</label>
                <div class="input-group">
                    <input type="text" id="current-emailaddress" :disabled="!isEmailEditing" class="form-control" v-model="profile.emailAddress">
                    <div class="input-group-append">
                        <button v-if="!isEmailEditing" class="btn btn-save" type="button" @click="editEmail">{{ $t('profile.edit') }}</button>
                        <button v-if="isEmailEditing" class="btn btn-save" type="button" @click="openPin('email')">{{ $t('profile.save') }}</button>
                        <button v-if="isEmailEditing"  class="btn btn-save" type="button" @click="isEmailEditing = false">{{ $t('profile.cancel') }}</button>
                    </div>
                </div>
            </div>
        </form>
        </div>
        
        <div class="card" > 
            <label for="new-pin">{{ $t('profile.updateMobile') }}</label>
            <div class="change-pin-form" v-if="!isEditing"> 
             
                <div class="input-group">
                    <div class="input-group-prepend">
                        <button class="btn text-white" type="button">
                            <span>+{{ mobileCode }}</span>
                        </button>
                    </div>
                    <input type="text" id="new-pin" :disabled="!isEditing"  class="form-control" v-model="profile.mobilePhone" @keypress="isNumber($event)">
                    <div class="input-group-append">
                        <button class="btn btn-save" type="button" @click="editMobile()">{{ $t('profile.edit') }}</button>
                    </div>
                </div>
            </div>
            <form class="change-pin-form mt-4" v-if="isEditing">
            
            <div class="form-group mb-4">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <button class="btn text-white" type="button" v-on:click="toggleCountryList">
                            <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
                            <span>+{{ mobileCode }}</span>
                        </button>
                    </div>
                    <input type="text" id="new-pin" class="form-control" v-model="profile.mobilePhone" @keypress="isNumber($event)">
                    <div class="input-group-append">
                        <button class="btn btn-save" type="button" @click="openPin('mobilePhone')">{{ $t('profile.save') }}</button>
                        <button v-if="isEditing"  class="btn btn-save" type="button" @click="isEditing = false">{{ $t('profile.cancel') }}</button>
                    </div>
                </div>
               <div class="country-list" v-if="showCountry">
                   <div class="row">
                       <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)">
                           <div >
                           <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                           </div>

                           <div class="country-name"> <span> {{ l.country }} </span> </div>  
                       </div>
                   </div>
               </div>
           </div>
                
        </form>
            
        </div>

        <div class="card" >
            <label for="update-2fa"> Update 2FA </label>
            <div class="form-check">
              <input class="form-check-input" type="radio"  value="EMAIL" v-model="profile.activeTwoFa" id="radioEmail">
              <label class="form-check-label" for="radioEmail">
                Email Address
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" value="SMS"  v-model="profile.activeTwoFa" id="radioMobile" checked>
              <label class="form-check-label" for="radioMobile">
                Mobile Number
              </label>
            </div>
        </div>
        
      
        

        
    </div>
    
    <ConfirmLogout />
    <PinModal :twoFa="twoFa" :emailAddress="profile.emailAddress" :mobileCode="mobileCode" :mobilePhone="profile.mobilePhone" :option="option"/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'

// Modals
import ConfirmLogout from './modal/ConfirmLogout.vue'
import PinModal from './modal/PinModal.vue'

export default {
    components: {
        Sidebar:  Sidebar,

        // Modals
        ConfirmLogout:  ConfirmLogout,
        PinModal: PinModal,
    },
    data() {
        return {
            option:'',
            isProfileEditing: false,
            isEditing: false,
            isEmailEditing: false,
            active: 'profile',
            showSidebar: false,
            country: 'US',
            mobileCode: '1',
            showCountry: false,
            twoFa: "",
           
            profile: {
                email: '',
                mobile: '',
                activeTwoFa: "",
                firstName: '',
                lastName: ''
            },

            list: {
                mobileCodes: []
            },

            systemMsg: '',
            showError: false,
		    showSuccess: false,
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
 
        toggleSidebar() {
            this.showSidebar = !this.showSidebar
        },

        editProfile: function(){
            this.isProfileEditing = true;
        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        openPin: function(option){
            this.option = option
            this.$bvModal.show('pin-modal'); 
        },
        editMobile(){
            this.isEditing = true
        },

        editEmail(){
            this.isEmailEditing = true
        },

        selectCountry: function(c) {
            this.showCountry = false;
            this.country = c.countryCode;
            this.mobileCode = c.mobileCode; 
        },

        toggleCountryList: function(){
            console.log('.')
            this.showCountry = !this.showCountry;
        },

        getProfile: function(){
            const options = {
                method: 'GET',
                headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId"),
            }, 
                url: '/cx/profile/',
            };

            this.axios(options)
            .then((response) => {
                this.init.profile = response.data;
                this.profile = response.data;

                this.mobilePhone = response.data.mobilePhone;

                let getCountry = this.list.mobileCodes.find(obj => obj["countryCode"] === response.data.presentAddress.country);

                
                
                if(response.data.mobilePhone.substring(0,2) != response.data.presentAddress.country) {
                    let parseMobilePrefix = response.data.mobilePhone.substring(0,2);

                    let getCountry = this.list.mobileCodes.find(obj => obj["mobileCode"] === parseMobilePrefix);

                    this.country = getCountry.countryCode;
                    this.mobileCode = parseMobilePrefix;
                    this.profile.mobilePhone = this.profile.mobilePhone.replace(this.mobileCode, "");;
                } else {
                    this.country = getCountry.countryCode;
                    this.mobileCode = getCountry.mobileCode;

                    let regex = new RegExp("^" + getCountry.mobileCode);
                    this.profile.mobilePhone = this.profile.mobilePhone.replace(regex, "");;
                }

                

                console.log(response.data.presentAddress.country)
                console.log(this.country)
                console.log(this.mobileCode)
            })
        },

        updateProfile() {
            const params = {
                firstName: this.profile.firstName,
                middleName: '',
                lastName: this.profile.lastName,
                birthYear: 2000,
                birthMm: 1,
                birthDd: 1,
                nationality: 'PH',
                "presentAddress.numberStreet": "-",
                "presentAddress.cityTown": "-",
                "presentAddress.country": "PH",
                "presentAddress.postalCode": "-",
                "presentAddress.provState": "-",
                homePhone: '-',
                "permanentAddress.numberStreet": "-",
                "permanentAddress.cityTown": "-",
                "permanentAddress.country": "PH",
                "permanentAddress.postalCode": "-",
                "permanentAddress.provState": "-",
                placeOfBirth: '-',
                occupation: '-',
                natureOfWork: '-',
                identityNumber: '-',
                mothersMaidenName: '-',
                maritalStatus: '-',
                employer: '-',
                sourceOfFunds: '-',
                grossMonthlyIncome: 0,
                isNameOnly: true
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/profile/updatebasic',
            };
            this.axios(options)
                .then((response) => {
                    this.showSuccess = true;
                    this.systemMsg = this.systemMsg = this.i18n.t('profile.nameUpdated');
                    this.isProfileEditing = false;
                    setTimeout(() => {
                        this.showSuccess = false;
                        this.systemMsg = "";
                    }, 3000)
                })
                .catch((error) => {
                    
                });
        },
        

        updatePhone() {
            const params = {
                mobilePhone: this.mobileCode + this.profile.mobilePhone,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updatemobile',
            };
            this.axios(options)
                .then((response) => {
                    this.showSuccess = true;
                    this.systemMsg = this.i18n.t('profile.mobileUpdated');

                    setTimeout(() => {
                        this.showSuccess = false;
                        this.systemMsg = "";
                    }, 3000)
                })
                .catch((error) => {

                    this.showError = true;
                    this.systemMsg = error.response.data.fieldErrors[0].defaultMessage;

                    setTimeout(() => {
                        this.showError = false;
                        this.systemMsg = "";
                    }, 3000)
                });
        },
      

        updateEmail() {
            const params = {
                emailAddress: this.profile.emailAddress,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updateemail',
            };
            this.axios(options)
                .then((response) => {
                    this.showSuccess = true;
                    this.systemMsg = this.i18n.t('profile.emailUpdated');

                    setTimeout(() => {
                        this.showSuccess = false;
                        this.systemMsg = "";
                    }, 3000)
                })
                .catch((error) => {

                    this.showError = true;
                    this.systemMsg = error.response.data.fieldErrors[0].defaultMessage;

                    setTimeout(() => {
                        this.showError = false;
                        this.systemMsg = "";
                    }, 3000)
                });
        },

        getMobileCodes: function() {
        let mobilecodes = '/assets/js/mobilecodes.json'

        this.axios.get(mobilecodes)
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
              console.log(err);

          })
      },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
    mounted() {
        this.getProfile();
        this.getMobileCodes();
    },
    watch: {
        'profile.activeTwoFa': function(newValue, oldValue) { 

            if (newValue != oldValue && oldValue != "") {
                this.twoFa = this.profile.activeTwoFa;
                this.openPin('twofa');
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .default-flag {
        width: 33px;
        margin-right:0.25em;
    }
    .country-list {
        position: absolute;
        background: #ccc;
        color: #333;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
            z-index: 3;
            width: 300px;
        .row {
            margin: 0 !important;
            .col-sm-6 {
                display: flex;
            padding: 0.5em;
            align-items: center;
            cursor: pointer;
            &:hover {
                background: darken(#ccc, 10%);
            }
            .country-name {
                margin-left: 0.5em;
            }

            span {
                font-size: 0.9em;
            }
            }
        }
    }

    .flag-list-img {
        width: 33px;
    }


    #content { 
        color:white;
        background-color:black; 
        padding:40px;
        min-height:100vh;

        .change-pin-form {
            width:500px;

            .form-title {
                font-family:OpenSans-SB;
                color:#c08000;
            }

            .form-control {
                background-color:#231f20;
                border:none;
                color:white;
            }

            .btn-save {
                width:100px;
                background:#404040;
                color:white;
                border-top-right-radius:10px;
                border-bottom-right-radius:10px;
            }
        }
    }
    .card{
        border: 1px solid #ccc;
        background-color: transparent !important;
        color: white;
        padding: 20px;
    }
    
</style>
