<template>
    <div id="sidebar">
        <div class="d-flex align-items-end justify-content-between text-right text-white">
            <div></div>
            <h4 style="cursor:pointer;" @click="close()">x</h4>
        </div>
        <div class="sidebar-header d-flex flex-wrap mb-5">
            <img :src="require('../assets/logo.png')" style="width:60px;" class="my-auto text-center">
            <h1 class="header-text my-auto ml-3">HOMS</h1>
        </div>
        <div class="profile">
            <div class="name">{{ init.profile.fullName }}</div>
            <div class="email">{{ init.profile.emailAddress }}</div>
        </div>
        <!-- Dashboard -->
        <div class="sidebar-link d-flex flex-wrap" :class="{active: active == 'dashboard'}" @click="redirectTo('')">
            <img :src="require('../assets/dash1.png')" alt="" class="sidebar-link-img my-auto" v-if="active != 'dashboard'">
            <img :src="require('../assets/dash.png')" alt="" class="sidebar-link-img my-auto" v-else>
            <h4 class="sidebar-link-text my-auto">{{ $t('home.sidebar.dashboard') }}</h4>
        </div>
        <div class="sidebar-link d-flex flex-wrap" :class="{active: active == 'profile'}" @click="redirectTo('profile')">
            <img :src="require('../assets/dash1.png')" alt="" class="sidebar-link-img my-auto" v-if="active != 'profile'">
            <img :src="require('../assets/dash.png')" alt="" class="sidebar-link-img my-auto" v-else>
            <h4 class="sidebar-link-text my-auto">{{ $t('home.sidebar.profile') }}</h4>
        </div>
        <!-- <div class="sidebar-link d-flex flex-wrap" :class="{active: active == 'withdraw'}" @click="send">
            <img :src="require('../assets/withdraw1.png')" alt="" class="sidebar-link-img my-auto" v-if="active != 'withdraw'">
            <img :src="require('../assets/withdraw2.png')" alt="" class="sidebar-link-img my-auto" v-else>
            <h4 class="sidebar-link-text my-auto">Withdraw</h4>
        </div> -->

        <div class="sidebar-link d-flex flex-wrap" :class="{active: active == 'history'}" @click="redirectTo('history')">
            <img :src="require('../assets/trans 1.png')" alt="" class="sidebar-link-img my-auto" v-if="active != 'history'">
            <img :src="require('../assets/trans.png')" alt="" class="sidebar-link-img my-auto" v-else>
            <h4 class="sidebar-link-text my-auto">{{ $t('home.sidebar.transactionHistory') }}</h4>
        </div>
        <div class="sidebar-link d-flex flex-wrap" :class="{active: active == 'change-pin'}" @click="redirectTo('change-pin')">
            <img :src="require('../assets/changepin1.png')" alt="" class="sidebar-link-img my-auto" v-if="active != 'change-pin'">
            <img :src="require('../assets/changepin.png')" alt="" class="sidebar-link-img my-auto" v-else>
            <h4 class="sidebar-link-text my-auto">{{ $t('home.sidebar.changePin') }}</h4>
        </div>
        <!-- <div class="sidebar-link d-flex flex-wrap" :class="{active: active == 'redeem'}" @click="redirectTo('redeem')">
            <img :src="require('../assets/redeem1.png')" alt="" class="sidebar-link-img my-auto" v-if="active != 'redeem'">
            <img :src="require('../assets/redeem.png')" alt="" class="sidebar-link-img my-auto" v-else>
            <h4 class="sidebar-link-text my-auto">Redeem</h4>
        </div> -->
        <!-- <b-dropdown id="dropdown-1" text="Dropdown Button" class="dropdown" no-caret menu-class="w-100 dropdownMenu" :toggle-class="{dropdownBtn: true, active: active == 'privatekey'}">
            <div slot="button-content" class="d-flex">
                <img :src="require('../assets/get private key 1.png')" alt="" class="sidebar-link-img my-auto">
                <h4 class="sidebar-link-text my-auto">Get Private Key</h4>
                <v-icon name="caret-down" class="my-auto ml-2" style="color:white;fill:white;" scale="2"/>
            </div>
            <b-dropdown-item @click="view">View</b-dropdown-item>
            <b-dropdown-item @click="importKey">Import</b-dropdown-item>
            <b-dropdown-item @click="generate">Generate</b-dropdown-item>
        </b-dropdown> -->
        <div class="sidebar-link d-flex flex-wrap">
            <img :src="require('../assets/support1.png')" alt="" class="sidebar-link-img">
            <h4 class="sidebar-link-text my-auto"> <a style="color:white;text-decoration:none;" href="mailto:support@homsworld.org">{{ $t('home.sidebar.support') }}</a></h4>
        </div>
        <div class="sidebar-link d-flex flex-wrap" @click="logout" id="logout">
            <img :src="require('../assets/logout1.png')" alt="" class="sidebar-link-img">
            <h4 class="sidebar-link-text my-auto">{{ $t('home.sidebar.signOut') }}</h4>
        </div>
    </div> 
</template> 

<script>
export default {
    props: {
        active: ''
    },
    data() {
        return {
            activeLink: ''
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        close() {
            console.log('s')
            this.$emit('close')
        },

        logout() {
            console.log('.')
            this.$emit('logout');
        },

        redirectTo(route) {
            if(this.active != route && this.active != 'privatekey') {
                console.log('active')
                this.$router.push('/' + route)
            }
        },
        send: function() {
            this.init.labelSend = "Withdraw";
            this.$bvModal.show('send-modal'); 
        },

        view() {
            console.log('.')
            this.$emit('view');
        },

        importKey() {
            console.log('.')
            this.$emit('import');
        },

        generate() {
            console.log('.')
            this.$emit('generate');
        },
    },

    mounted() {
        
    }
}
</script>

<style scoped lang="scss">
    #sidebar {
        position:fixed;
        padding:20px;
        width:25em;
        background-color:#0e0e0e;
        height:100vh;
        max-height: calc(100vh - 110px);
        overflow-y: auto;
        z-index:99999;
        .sidebar-header {
            .header-text {
                color:#c08000;
                font-family:OpenSans-SB;
            }
        }
        .profile {
            padding:10px 20px;
            color:white;
            margin-bottom:30px;

            .name {
                font-size:25px;
                font-family:OpenSans-SB;
            }

            .email {
                font-family:OpenSans-L;
            }
        }
        .sidebar-link {
            margin:0 auto;
            margin-bottom:15px;
            width: 100%;
            padding:20px 10px;
            border-radius:10px;
            cursor:pointer;
            &.active {
                background-image: linear-gradient(to bottom, #231f20, #070607);
                .sidebar-link-text {
                    color:#c08000;
                    font-family:OpenSans-SB;
                }
            }
            .sidebar-link-img {
                width:30px;
                object-fit:contain;
                height:auto;
                margin-right:4px;
            }
            .sidebar-link-text {
                color:white;
                margin-left:4px;
                font-family:OpenSans;
            }
        }

        #logout {
            &:hover {
                background-image: linear-gradient(to bottom, #231f20, #070607);
                .sidebar-link-text {
                    color:#c08000;
                    font-family:OpenSans-SB;
                }
                .sidebar-link-img {
                    src:url(../assets/logout.png);
                }
            }
        }

        .dropdown {
            margin:0 auto;
            margin-bottom:15px;
            width: 100%;
            border-radius:10px;
            cursor:pointer;
        }

        ::v-deep .dropdownBtn {
            margin:0 auto;
            width: 100%;
            padding:20px 10px;
            border-radius:10px;
            cursor:pointer;
            font-size:1.5rem;
            background:none;
            border:none;

            img {
                width:30px;
                margin-right:4px;
            }

            .sidebar-link-text {
                color:white;
                margin-left:4px;
                font-family:OpenSans;
            }

            &.active {
                background-image: linear-gradient(to bottom, #231f20, #070607);
                .sidebar-link-text {
                    color:#c08000;
                    font-family:OpenSans-SB;
                }
                .sidebar-link-img {
                    src:url(../assets/logout.png);
                }
            }

            &:hover {
                background-image: linear-gradient(to bottom, #231f20, #070607);
                .sidebar-link-text {
                    color:#c08000;
                    font-family:OpenSans-SB;
                }
                .sidebar-link-img {
                    src:url(../assets/logout.png);
                }
            }
        }
    }
</style>