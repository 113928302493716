<template>
	<b-modal id="logout-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" style="top:30%;">

		<template #modal-header> 
			<div></div>
			<h5>
				{{ $t('modal.confirmLogout.areYouSure') }}
			</h5> 
			<div></div>

    </template>


		<div class="d-flex text-center justify-content-around"> 
			<button class="btn btn-main" @click="logout">{{ $t('modal.confirmLogout.ok') }}</button>
			<button class="btn btn-main" @click="close">{{ $t('modal.confirmLogout.cancel') }}</button>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  name: 'ExportImportFund',
  components: {
  },
  data: function() {
      return  {
      	
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
		logout: function(){
        this.axios.get('/cx/logout')
            .then((response) => { 
              
              window.location.href = "/login";
                
            }).catch((err) => { 
              console.log(err)

            })
      },

		 close: function(){
   				this.$bvModal.hide('logout-modal'); 
   		},
   	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   p {
	   margin-top:0px;
	   padding-top:0px;
   }

   h5 {
	   font-weight:bold;
	   margin-top:5px;
     font-size:2em;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }

	.btn-main {
   width:40%;
 }

	@media screen and (max-width:1000px) {
		div {
			font-size:0.86em;
		}

		div h5 {
			font-size:2em;
		}
	}
	
</style>
